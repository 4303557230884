
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,

  IonButtons, IonBackButton
} from "@ionic/vue";
import {
  globeOutline,
  logoFacebook,
  mailOutline,
  logoLinkedin,
} from "ionicons/icons";
import { useStore } from "vuex";
import AppContent from "../components/AppContent.vue";
import { computed, onMounted } from "vue-demi";

export default {
  name: "Tab3",
  components: {

    AppContent,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
  
    IonButtons, 
    IonBackButton
  },
  setup() {
    const store = useStore();
    const menuStyle = store.state.menu;
    const contentStyle = store.state.content;

    const aboutContent = computed(() => {
      return store.state.app.appSettings || {}});

    onMounted(() => {
      store.dispatch("axAppSettings");
    });

    return {
      aboutContent,
      globeOutline,
      logoFacebook,
      mailOutline,
      logoLinkedin,
      contentStyle,
      menuStyle,
    };
  },
};
