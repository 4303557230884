import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_app_content = _resolveComponent("app-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, {
            style: _normalizeStyle($setup.menuStyle)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.aboutContent.aboutUsTitle), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["style"])
        ]),
        _: 1
      }),
      _createVNode(_component_app_content, {
        fullscreen: true,
        style: _normalizeStyle($setup.contentStyle)
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "content",
            innerHTML: $setup.aboutContent.aboutUsDescription
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["style"])
    ]),
    _: 1
  }))
}